<template>
  <b-modal id="modal-review-product" body-class="pt-0" centered hide-footer size="lg" @hidden="$emit('resetRating'); resetData();">
      <template #modal-header>
        <h5 class="modal-title">Review</h5>
        <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-review-product')">&times;</button>
      </template>
      <template v-if="review">
        <div class="row modal-review-product-liz-point-info" v-if="lizpointSettings?.status && lizpointSettings.loyalty_bonus > 0">
          <div class="col-md-12 d-flex py-3">
            <div class="review-product-liz-point-img-container">
              <div class="review-product-liz-point-img"></div>
            </div>
            <div class="ml-2 review-product-liz-point-description">
              <div class="text-gold">Dapatkan <b>{{ lizpointSettings.loyalty_bonus }} Liz-point!</b></div>
              <small v-if="lizpointSettings.min_character > 0 || lizpointSettings.min_image_upload > 0">
                {{ setDescriptionLizPointReviewSetting(lizpointSettings) }}
              </small>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex">
            <img
              :src="review.image_url"
              alt=""
              class="w-120px rounded"
              @error="replaceByDefaultImage"
            />
            <div class="ml-3">
              <div class="modal-review-brand-name">{{ review.brand_name }}</div>
              <div class="modal-review-product-name bold">{{ review.product_name }}</div>
              <!-- <div class="d-flex flex-lg-row flex-column align-items-lg-center mt-1 text-grey"> -->
              <div class="modal-review-product-var text-grey">
                <span>Jumlah: {{ review.quantity }}</span>
                <span v-if="review.color"> | Warna: {{ review.color }}</span>
                <span v-if="review.size"> | Ukuran: {{ review.color }}</span>
                <!-- <div class="payment-detail">
                  Color: <span class="no-bold">{{ review.color }}</span>
                </div>
                <div class="payment-detail ml-lg-3">
                  Size: <span class="no-bold">{{ review.size }}</span>
                </div>
                <div class="payment-detail ml-lg-3">
                  QTY: <span class="no-bold">{{ review.quantity }}</span>
                </div> -->

              </div>
              <!-- </div> -->
              <div class="modal-review-rating mt-1">
                <fawesome-pro
                  v-for="stars in 5"
                  :key="stars"
                  :variant="rating >= stars ? 'fas' : 'far'"
                  icon="star"
                  class="pr-1 cursor-pointer text-warning"
                  @click.native="rating = stars"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <b-form-textarea
              id="review-comment"
              v-model="comment"
              placeholder="Tell others why you like this product..."
              :formatter="formatComment"
              no-resize
            ></b-form-textarea>
            <small class="d-block text-grey text-right">{{ comment.length }}/{{ maxCharForComment }}</small>
            <!-- <b-form-group
              id="fieldset-1"
              description="Let us know your name."
            >
              <b-form-textarea
                id="review-comment"
                v-model="comment"
                placeholder="Tell others why you like this product..."
                no-resize
              ></b-form-textarea>
            </b-form-group> -->
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12 img-review-list-container">
            <!-- Selected review image list -->
            <div 
              class="img-review-container editable"
              :key="indexImage"
              v-for="(pathSrc, indexImage) in reviewImage.path"
            >
              <label :for="`update-review-img-${indexImage}`">
                <img :id="`review-img-${indexImage}`" class="cursor-pointer" :src="pathSrc" alt="" />
              </label>
              <fawesome-pro variant="far" icon="times" class="review-img-delete cursor-pointer" @click.native="removeImage(indexImage)" />
              <input
                type="file"
                :id="`update-review-img-${indexImage}`"  
                class="d-none"
                @input="uploadFile($event, indexImage, true)"
                accept="image/*"
              />
            </div>
            <!-- Input file for review image -->
            <div
              class="img-review-container"
              :class="{'d-none' : reviewImage.file.length >= 5}"
            >
              <label for="insert-review-img">
                <img
                  class="w-100 cursor-pointer"
                  src="@/assets/img/icon-upload.png"
                  alt=""
                />  
              </label> 
              <input
                type="file"
                id="insert-review-img"
                class="d-none"
                @input="uploadFile($event)"
                @click="$event.target.value=''"
                accept="image/*"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div 
              class="chip mr-2 mt-2 cursor-pointer"
              :class="{ 'activated': tag.is_selected }"
              @click="tag.is_selected = !tag.is_selected"
              v-for="(tag, indexTag) in tags" 
              :key="indexTag"
            >
              {{ tag.name }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2">
            <div class="styled-checkbox">
              <label class="container-checkbox m-0">
                Send as anonymous
                <input type="checkbox" v-model="isAnonymous"/>
                <span class="checkmark" style="top: -2px"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 text-right">
            <button
              type="button"
              class="btn btn-wz-proceed w-lg-auto w-100"
              @click="submitData"
            >
              <font-awesome v-if="isLoading" icon="spinner" class="mr-1" spin />
              SEND REVIEW 
            </button>
          </div>
        </div>
      </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalReviewPerProduct",
  props: ["review"],
  data() {
    return {
      isLoading: false,
      tags: [],
      rating: 0,
      comment: "",
      reviewImage : {
        path: [],
        file: []
      },
      isAnonymous: false,

      lizpointSettings: null,

      maxCharForComment: 250,
    };
  },
  mounted() {
    this.getProductReviewLizpointSetting();
    this.getReviewTags();
  },
  methods: {
    async getProductReviewLizpointSetting() {
      try {
        const res = await this.$api.user.getProductReviewLizpointSetting();
        if (res.status === 200) this.lizpointSettings = res.data.data; 
        else this.$helpers.toaster.make(res.data.message, "danger");
      } catch (error) {
        console.error(error);
      }
    },
    async getReviewTags() {
      try {
        const res = await this.$api.user.getProductReviewTags();
        if (res.status === 200) this.setReviewTags(res.data.data);
        else this.$helpers.toaster.make(res.data.message, "danger");
      } catch (e) {
        console.error(e)
      }
    },
    setReviewTags(data) {
      this.tags = data.map(el => ({...el, is_selected: false}));
    },
    uploadFile(event, indexImage, update = false) {
      const file = event.target.files[0];

      if (!["image/png", "image/jpeg"].includes(file["type"]) && file) return this.$helpers.toaster.make("File must be an image(jpeg/png)", "danger");
      
      //* Add / update file to array reviewImage.file
      if(update) this.$set(this.reviewImage.file, indexImage, file)
      else this.reviewImage.file.push(file);

      //* Add / update path image for preview image to array reviewImage.path
      let reader = new FileReader();
      reader.onload = (e) => {
        if (update) this.$set(this.reviewImage.path, indexImage, e.target.result)
        else this.reviewImage.path.push(e.target.result);
      };
      reader.readAsDataURL(file);
      this.$emit("input", file);
    },
    removeImage(indexImage) {
      this.reviewImage.path.splice(indexImage, 1)
      this.reviewImage.file.splice(indexImage, 1)
    },
    setSubmittedTags(tags) {
      const result = tags.reduce((acc, curVal) => {
        if (curVal.is_selected) acc += `;${curVal.name}` 
        return acc 
      }, "")
      return result;
    },
    formatComment(e){
      return String(e).substring(0, this.maxCharForComment);
    },
    async submitData() {
      try {
        this.isLoading = true;

        if (this.rating <= 0) {
          this.isLoading = false;
          return this.$helpers.toaster.make("Rating cannot be empty", "danger");
        }

        const form = new FormData();
        const data = {
          title: "",
          anonymous: this.isAnonymous,
          comment: this.comment,
          image_url: "",
          tag: this.setSubmittedTags(this.tags),
          rating: this.rating,
          sales_order_detail_id: this.review.id,
        }

        form.append("data", JSON.stringify(data));

        for (let i = 0; i < this.reviewImage.file.length; i++) {
          form.append(`image_${i + 1}`, this.reviewImage.file[i]);
        }

        const res = await this.$api.user.saveProductReviewV2(form);
        if (res.status === 200) {
          this.isLoading = false;
          this.$helpers.toaster.make("Products Succesfully Reviewed", "success")
          this.$bvModal.hide("modal-review-product")
          this.$emit("refreshList")
        }
      } catch (e) {
        this.isLoading = false;
        this.$helpers.toaster.make(e.response.data.message, "danger");
        console.error(e)
      }
    },
    resetData() {
      this.comment = "";
      this.reviewImage = {
        path: [],
        file: []
      };
      this.isAnonymous = false;
      this.setReviewTags(this.tags);
    },
    replaceByDefaultImage(event) {
      event.target.src = require("@/assets/img/default-image.png")
      event.target.classList.add("p-4", "default-image")
    },
    setDescriptionLizPointReviewSetting(setting) {
      let text = "Beri penilaian dengan minimum";
      if (setting.min_character > 0) text += ` ${setting.min_character} karakter`;
      if (setting.min_character > 0 && setting.min_image_upload > 0) text += " dan";
      if (setting.min_image_upload > 0) text += ` ${setting.min_image_upload} foto`;
      text += ".";
      return text;
    }
  },
  watch: {
    'review.rating'(val) {
      if (!val) return
      this.rating = val;
    }
  }
};
</script>

<style scoped>
.container-checkbox {
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.modal-review-product-var, .modal-review-product- {
  font-size: 14px;
}
.modal-review-rating {
  font-size: 24px;
}
@media only screen and (max-width: 992px){
  .modal-review-product-name {
    font-size: 14px;
  }
  #review-comment::placeholder, .review-product-var, .modal-review-brand-name {
    font-size: 12px;
  }
  .modal-review-rating {
    font-size: 22px;
  }
}
.modal-review-product-liz-point-info {
  background: #F3E8D4;
}
.review-product-liz-point-description {
  line-height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.review-product-liz-point-img-container {
  min-width: 35px;
  min-height: 35px;
  padding: 5px;
  background: #E5A800;
  border-radius: 50%;
  margin: auto 0;
}
.review-product-liz-point-img {
  border-radius: 50%;
  background: url('../../assets/img/liz-point-hover.svg') no-repeat center;
  background-size: 100%;
  width: 100%;
  padding-top: 100%;
  border: .5px solid #fff;
}
</style>